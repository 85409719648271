* {
  margin: 0;
  box-sizing: border-box;
  font-family: 'Poppins', sans-serif;
  scroll-behavior: smooth;
}

body {
  min-height: 100vh;
  color: #fff;
}

a {
  all: unset;
}
